<template>
  <div class="prepaid-recharge">
    <img
      class="recharge-tips"
      src="@/assets/task/recharge-tips.png"
      alt=""
      srcset=""
    />
    <div class="tips">我的补给箱</div>
    <!-- 价格100，500，1000，2000，5000，10000就行 -->
    <div class="recharge">
      <div class="recharge-item">
        <div>充值金额</div>
        <div>首次补给</div>
      </div>
      <div class="recharge-item" v-for="(item, index) in list" :key="index">
        <div style="font-weight: bold">{{ item }}</div>
        <div>
          <img
            src="@/assets/task/recharge.png"
            alt=""
            srcset=""
            @click="getChongzhils(index + 1)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [100, 500, 1000, 2000, 5000, 10000],
    };
  },
  methods: {
    async getChongzhils(id) {
      const params = {
        player_id: localStorage.getItem("id"),
        id,
      };
      const { data } = await this.$axios.post(
        "/index/Activity/czls",
        this.$qs.stringify(params)
      );

      if (data.status) {
          this.$message.success("Claimed Success");
        } else {
          this.$message.error(data.msg);
        }

     
    },
  },
};
</script>

<style lang="less" scoped>
.prepaid-recharge {
  color: #fff;
  text-align: center;
  .tips {
    font-size: 22px;
    margin-top: 20px;
  }
  .recharge-tips {
    width: 95%;
    max-width: 400px;
  }
  .recharge {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 30px;
    &-item:first-child {
      margin-bottom: 20px;
    }
    &-item {
      display: flex;
      align-items: center;
      width: 100%;
      & > div {
        flex: 1;
        font-size: 14px;
        img {
          width: 30%;
          max-width: 100px;
        }
      }
    }
  }
}
</style>
