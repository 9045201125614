<template>
  <div class="sign-day">
    <img
      class="sign_tips"
      src="@/assets/task/4c0298b7033572834c21c8c4ba92b3e.jpg"
      alt=""
      srcset=""
    />
    <span class="total_sign-num">本月累计签到 {{ dayNum }} 天</span>
    <img
      class="sign_immediate"
      src="@/assets/task/sign_btn.png"
      alt=""
      srcset=""
      @click="startSign"
    />
    <div class="accumlate">
      <div
        class="accumlate-item"
        v-for="item in accumlates"
        :key="item.day"
        @click="contniuSignRecharge(item.day)"
      >
        <img :src="item.img" alt="" srcset="" />
        <span>累计{{ item.day }}天领取</span>
      </div>
    </div>

    <div class="sign-rule">
      <div class="content">
        <div class="content-item" v-for="item in accumlates" :key="item.day">
          <img :src="tipsImage" alt="" srcset="" />
          <span
            >当月累计签到 {{ item.day }} 天获得最高 {{ item.jb }}金币随机奖励</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tipsImage:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAbCAYAAABiFp9rAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsSAAALEgHS3X78AAADGUlEQVRIx5XWS6hWVRQH8N/+uoRWFJQQ4U64ZYNETSqJBlkkivkgsmMRghHUVLTHRLNUuvaGaNLMgZEN2mH5SFMiLBzlwK5mQg/DdkRhT0ihQafB2Rc+P77vu8c9O2vttf/r/z977bWCSVad4o14CAswC9cU1584gX3YFaqch50ThgDMxRiWYRyHcBw/ly3XYTYWY24B3BiqPN4KqE6xgw14DnuxNVT52CSs5+FZ3I+tGAtV/m8gUJ3ipXgbC/FYqPKeyaTtiV+KHfgUq0OV/53wdbo2BWzHHbgzVHlPneIlFwMUqvxRib8d28uZFwJhLR7AilDlb4ptpE7xtTrF0YsA+w6LcB/WXSBdneIMfI0nQpV39sixGB/gZbwSqny+pYyrioyzQpVPTzDajCO9ICXDg/i47Dlep7i8JbP3cLTECXWK0/ATFoUqfzYgu1GcxJRi2oP1RaZhrO7WlEXsYCXODAIp2Z3Gq12mFYXd5jrFqUPiDuN7rOxorvL+Fmq8hDNd31PxPE7UKa4YErcfCzua6v6ihebn8FQf1w3YXae4t05xZh//UczpIPZkOgws4eAA9zKM1ylu6ZHzR+UfXYlzbYDKemuIb6rm6Tpap3h1sf2Fyzv4G5e1QSiV/uQk23Zjeajy7+X7KvwzgowZLdmswl0DfN9iXajyvh779cgdTU+5rQWbKS684hPrfJFrTh8QuBUnRvBJCzngmT7MPywsfhgStxRvdr8M94YqHxnAZjpO4YoumdaGKg+tvzrFBYXI9E6o8lm8q2lcg9ZYATmPTZg9GUhZL2BnqPKvI8WwBSfrFB8MVX6/J6v5WINdRaZWNVenuBLz8WivY32d4m/d1V2nGOoUd9QpLmlzeFfczDrFs3WKT0/YuhvfG5p36VCZfGhe68dDlQ9cBMio5vU4gNcn7P1mhndwD9a0/A/d8Us0ze5zPNI9MwyagjZpLscubGsxBd2CjZpR4EVsHjoF9Qnepun9xzQNbBy/lC3Xaua5RZhXZN8Qqvxlv/MGAnUB3oSHNZPqzZhWXH/gKxxGClU+Neyc/wF0QB/qGtoTJAAAAABJRU5ErkJggg==",
      accumlates: [
        {
          day: 7,
          img: require("@/assets/task/sign_get_1.png"),
          jb: 10,
        },
        {
          day: 14,
          jb: 30,
          img: require("@/assets/task/sigin_get_2.png"),
        },
        {
          jb: 66,
          day: 28,
          img: require("@/assets/task/sigin_get_3.png"),
        },
      ],
      dayNum: 0,
    };
  },
  created() {
    this.userSign();
  },
  methods: {
    async userSign() {
      const params = {
        player_id: localStorage.getItem("id"),
      };
      const {
        data: { data },
      } = await this.$axios.post(
        "/index/User/sign",
        this.$qs.stringify(params)
      );
      this.dayNum = data.list[0].sign;
    },
    async startSign() {
      const params = {
        player_id: localStorage.getItem("id"),
      };
      const { data } = await this.$axios.post(
        "/index/User/sign_in",
        this.$qs.stringify(params)
      );
      if (data.status) {
        this.$message.success("Check-in success");
        this.dayNum += 1;
      } else {
        this.$message.error(data.msg);
      }
    },
    async contniuSignRecharge(id) {
      const params = {
        player_id: localStorage.getItem("id"),
        sign__id:id,
      };
      const { data } = await this.$axios.post(
        "/index/User/sign_welfarenew",
        this.$qs.stringify(params)
      );
      if (data.status) {
        this.$message.success("领取成功");
      } else {
        this.$message.error(data.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.sign-day {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;

  .sign_tips {
    width: 80%;
    max-width: 600px;
  }
  .sign_immediate {
    max-width: 300px;
    width: 40%;
    cursor: pointer;
  }
  .total_sign-num {
    font-size: 26px;
    color: #fff;
    margin: 10px 0;
  }

  .accumlate {
    display: flex;
    align-items: center;
    margin-top: 20px;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      cursor: pointer;
      max-width: 200px;
      img {
        width: 60%;
      }
      span {
        color: #fff;
        font-size: 14px;
      }
    }
  }

  .sign-rule {
    width: 320px;
    height: calc((625 / 850) * 320px);
    margin-top: 50px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../../assets/task/sign_rule.png");
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .content {
      padding-bottom: 20px;
      &-item {
        display: flex;
        padding: 0 20px;
        font-size: 13px;
        text-align: justify;
        margin-bottom: 5px;
        img {
          width: 17px;
          height: 17px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
